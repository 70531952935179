
'use strict';

import React from 'react';

import MuxPlayer from '@mux/mux-player-react';


// https://github.com/muxinc/elements/tree/main/packages/mux-player-react

class CourseBlockVideo extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let block = this.props.block;
    return (
      <div className='pi3courseVideo' id={this.props.id}>
        <MuxPlayer
          streamType='on-demand'
          playbackId={block.muxPlaybackId}
          thumbnailTime={2}
          playbackRates={[1,2]}
        />
      </div>
    );
  }
}

export default CourseBlockVideo;