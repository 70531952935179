
import ajaxAdmin from '../../src/pi3-frontend/ajax-admin.js';
import React from 'react';
import ReactQuill from 'react-quill';
import ReactSelect from 'react-select';
import ReactSelectCreatable from 'react-select/creatable';
import {css} from 'emotion';
import {formDefinitionFillIds} from '../../src/helpers/formDefinitionFillIds.js';
import {ReactSortable} from 'react-sortablejs';
import {useEffect} from 'react';
import {useFirstRender} from '../../src/utilities/React.js';
import {useStateAtom} from '../../src/utilities/React/UseStateAtom.js';
import {wrapStateAtom} from '../../src/libs/reactjs/wrapStateAtom.js';
import {yamlParse} from '../../src/utilities/yaml/yamlParse.js';
import {yamlStringify} from '../../src/utilities/yaml/yamlStringify.js';

export default function (props) {

  const formInfo = useStateAtom(null);
  const nameSource = useStateAtom('');
  const definitionASTSource = useStateAtom('');
  const definitionAST = useStateAtom(null);
  const pageActiveIndex = useStateAtom(0);
  const blockActiveIndex = useStateAtom(-1);
  const subBlockActiveIndex = useStateAtom(-1);

  useFirstRender(async () => {
    formInfo((await ajaxAdmin.adminFormLoad(props.formId)).form);
    nameSource(formInfo().name);
    definitionAST(yamlParse(formInfo().definitionScript));
    definitionASTSource(yamlStringify(definitionAST()));
  });

  return (<>

    {definitionAST() && (<>

      <div
        className={css`
          display: flex;
          width: 100%;
          gap: 16px;
          padding: 0 16px;
        `}
      >
        <div
          className={css`
            flex: none;
          `}
        >
          Form name:
        </div>
        <div
          className={css`
            flex: 1 1 0%;
          `}
        >
          <input
            type="text"
            className={css`
              width: 100%;
            `}
            value={formInfo().name}
            onChange={(e) => {
              formInfo({...formInfo(), name: e.target.value});
            }}
          />
        </div>
      </div>

      <div
        className={css`
          margin-top: 16px;
          display: flex;
          flex-direction: row;
          align-content: stretch;
          align-items: stretch;
          gap: 16px;
          width: 100%;
        `}
      >
        <div
          className={css`
            flex: none;
            width: 288px;
            border-style: solid;
            border-width: 1px;
            border-color: #d1d5db;
            height: calc(100vh - 230px);
            overflow: auto;
          `}
        >
          <FormStructure
            definitionAST={definitionAST}
            pageActiveIndex={pageActiveIndex}
            blockActiveIndex={blockActiveIndex}
            subBlockActiveIndex={subBlockActiveIndex}
          />
        </div>
        <div
          className={css`
            width: 100%;
            height: calc(100vh - 230px);
            overflow: auto;
          `}
        >
          {(() => {
            //if (definitionAST().pages[pageActiveIndex()].blocks[blockActiveIndex()].component == 'Group')
            if (subBlockActiveIndex() != -1) {
              if (!definitionAST().pages[pageActiveIndex()]?.blocks?.[blockActiveIndex()]?.blocks?.[subBlockActiveIndex()])
                return (<></>);
              return (<>
                <BlockEdit
                  noGroup={true}
                  block={wrapStateAtom(
                    definitionAST().pages[pageActiveIndex()].blocks[blockActiveIndex()].blocks[subBlockActiveIndex()],
                    (state) => {
                      definitionAST({
                        ...definitionAST(),
                        pages: [
                          ...definitionAST().pages.slice(0, pageActiveIndex()),
                          {
                            ...definitionAST().pages[pageActiveIndex()],
                            blocks: [
                              ...definitionAST().pages[pageActiveIndex()].blocks.slice(0, blockActiveIndex()),
                              {
                                ...definitionAST().pages[pageActiveIndex()].blocks[blockActiveIndex()],
                                blocks: [
                                  ...definitionAST().pages[pageActiveIndex()].blocks[blockActiveIndex()].blocks.slice(0, subBlockActiveIndex()),
                                  state,
                                  ...definitionAST().pages[pageActiveIndex()].blocks[blockActiveIndex()].blocks.slice(subBlockActiveIndex() + 1),
                                ],
                              },
                              ...definitionAST().pages[pageActiveIndex()].blocks.slice(blockActiveIndex() + 1),
                            ],
                          },
                          ...definitionAST().pages.slice(pageActiveIndex() + 1),
                        ],
                      });
                    },
                  )}
                />
              </>);
            }
            if (blockActiveIndex() != -1) {
              if (!definitionAST().pages[pageActiveIndex()]?.blocks?.[blockActiveIndex()])
                return (<></>);
              return (<>
                <BlockEdit
                  block={wrapStateAtom(
                    definitionAST().pages[pageActiveIndex()].blocks[blockActiveIndex()],
                    (state) => {
                      definitionAST({
                        ...definitionAST(),
                        pages: [
                          ...definitionAST().pages.slice(0, pageActiveIndex()),
                          {
                            ...definitionAST().pages[pageActiveIndex()],
                            blocks: [
                              ...definitionAST().pages[pageActiveIndex()].blocks.slice(0, blockActiveIndex()),
                              state,
                              ...definitionAST().pages[pageActiveIndex()].blocks.slice(blockActiveIndex() + 1),
                            ],
                          },
                          ...definitionAST().pages.slice(pageActiveIndex() + 1),
                        ],
                      });
                    },
                  )}
                />
              </>);
            }
            if (!definitionAST().pages[pageActiveIndex()])
              return (<></>);
            return (<>
              <BlockEdit
                block={wrapStateAtom(
                  definitionAST().pages[pageActiveIndex()],
                  (state) => {
                    definitionAST({
                      ...definitionAST(),
                      pages: [
                        ...definitionAST().pages.slice(0, pageActiveIndex()),
                        state,
                        ...definitionAST().pages.slice(pageActiveIndex() + 1),
                      ],
                    });
                  },
                )}
              />
            </>);
          })()}
        </div>
      </div>

      <div
      className={css`
        text-align: right;
        padding: 8px;
      `}
      >
        <button
          className={css`
            padding: 4px;
          `}
          onClick={async (e) => {
            await ajaxAdmin.adminFormUpdate(props.formId, {
              name: formInfo().name,
              definitionScript: yamlStringify(definitionAST()),
            });
            nameSource(formInfo().name);
            definitionASTSource(yamlStringify(definitionAST()));
          }}
        >
          {nameSource() != formInfo().name || definitionASTSource() != yamlStringify(definitionAST()) ? 'Save' : 'Saved'}
        </button>
      </div>

    </>)}

  </>);

};

const FormStructure = (props) => {
  return (
    <div
      className={css`
        width: 100%;
        border-style: solid;
        border-width: 1px;
        border-color: #d1d5db;
        padding: 0 16px;
        height: auto;
      `}
    >
      <ReactSortable
        list={props.definitionAST().pages.map(v => ({item: v}))}
        setList={(state) => {
          props.definitionAST({...props.definitionAST(), pages: state.map(i => i.item)});
        }}
        // @see https://sortablejs.github.io/Sortable/#nested
        group="form-structure-pages"
        animation="150"
        fallbackOnBody="true"
        swapThreshold="0.65"
        // @see https://sortablejs.github.io/Sortable/#handle
        handle=".handle"
        filter=".filtered"
      >
        {props.definitionAST().pages.map((page, pageIndex) => (<>
          <div
            key={pageIndex}
            className={css`
              pointer-events: auto;
              cursor: pointer;
            `}
          >
            <div
              className={css`
                display: flex;
                ${true
                  && pageIndex == props.pageActiveIndex()
                  && -1 == props.blockActiveIndex()
                  && -1 == props.subBlockActiveIndex()
                  && `
                    font-weight: 700;
                  `
                }
              `}
              onClick={(e) => {
                props.pageActiveIndex(pageIndex);
                props.blockActiveIndex(-1);
                props.subBlockActiveIndex(-1);
              }}
            >
              <div
                className={'handle ' + css`
                  display: grid;
                  place-content: center;
                  place-items: center;
                  width: 32px;
                  cursor: move;
                `}
              >
                <IconDrag />
              </div>
              <div
                className={css`
                  display: grid;
                  place-content: center;
                  place-items: center;
                `}
              >
                <div>
                  Page {pageIndex + 1}
                  &nbsp;
                  <button
                    className={css`
                      display: inline-block;
                      padding: 4px;
                    `}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const pageIndex = props.definitionAST().pages.findIndex(v => v === page);
                      props.definitionAST({
                        ...props.definitionAST(),
                        pages: [
                          ...props.definitionAST().pages.slice(0, pageIndex),
                          ...props.definitionAST().pages.slice(pageIndex + 1),
                        ],
                      });
                    }}
                  >
                    X
                  </button>
                </div>
              </div>
            </div>
            <div
              className={css`
                padding-left: 16px;
              `}
            >
              <ReactSortable
                list={page.blocks.map(v => ({item: v}))}
                setList={(state) => {
                  const pageIndex = props.definitionAST().pages.findIndex(v => v === page);
                  props.definitionAST({
                    ...props.definitionAST(),
                    pages: [
                      ...props.definitionAST().pages.slice(0, pageIndex),
                      (() => {
                        const page = props.definitionAST().pages[pageIndex];
                        page.blocks = state.map(i => i.item);
                        return page;
                      })(),
                      ...props.definitionAST().pages.slice(pageIndex + 1),
                    ],
                  });
                }}
                // @see https://sortablejs.github.io/Sortable/#nested
                group="form-structure-blocks"
                animation="150"
                fallbackOnBody="true"
                swapThreshold="0.65"
                // @see https://sortablejs.github.io/Sortable/#handle
                handle=".handle"
                filter=".filtered"
              >
                {page.blocks.map((block, blockIndex) => (<>
                  <div
                    key={blockIndex}
                    className={css`
                      pointer-events: auto;
                      cursor: pointer;
                    `}
                  >
                    <div
                      className={css`
                        display: flex;
                        pointer-events: auto;
                        cursor: pointer;
                        ${true
                          && pageIndex == props.pageActiveIndex()
                          && blockIndex == props.blockActiveIndex()
                          && -1 == props.subBlockActiveIndex()
                          && `
                            font-weight: 700;
                          `
                        }
                      `}
                      onClick={(e) => {
                        props.pageActiveIndex(pageIndex);
                        props.blockActiveIndex(blockIndex);
                        props.subBlockActiveIndex(-1);
                      }}
                    >
                      <div
                        className={'handle ' + css`
                          display: grid;
                          place-content: center;
                          place-items: center;
                          width: 32px;
                          cursor: move;
                        `}
                      >
                        <IconDrag />
                      </div>
                      <div
                        className={css`
                          display: grid;
                          place-content: center;
                          place-items: center;
                        `}
                      >
                        <div>
                          Block {blockIndex + 1}
                          &nbsp;
                          <button
                            className={css`
                              display: inline-block;
                              padding: 4px;
                            `}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              const pageIndex = props.definitionAST().pages.findIndex(v => v === page);
                              const blockIndex = props.definitionAST().pages[pageIndex].blocks.findIndex(v => v === block);
                              props.definitionAST({
                                ...props.definitionAST(),
                                pages: [
                                  ...props.definitionAST().pages.slice(0, pageIndex),
                                  (() => {
                                    const page = props.definitionAST().pages[pageIndex];
                                    page.blocks = [
                                      ...props.definitionAST().pages[pageIndex].blocks?.slice?.(0, blockIndex),
                                      ...props.definitionAST().pages[pageIndex].blocks?.slice?.(blockIndex + 1),
                                    ];
                                    return page;
                                  })(),
                                  ...props.definitionAST().pages.slice(pageIndex + 1),
                                ],
                              });
                            }}
                          >
                            X
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className={css`
                        padding-left: 32px;
                      `}
                    >
                      <ReactSortable
                        list={(block.blocks ?? []).map(v => ({item: v}))}
                        setList={(state) => {
                          const pageIndex = props.definitionAST().pages.findIndex(v => v === page);
                          const blockIndex = props.definitionAST().pages[pageIndex].blocks.findIndex(v => v === block);
                          props.definitionAST({
                            ...props.definitionAST(),
                            pages: [
                              ...props.definitionAST().pages.slice(0, pageIndex),
                              (() => {
                                const page = props.definitionAST().pages[pageIndex];
                                page.blocks = [
                                  ...props.definitionAST().pages[pageIndex].blocks?.slice?.(0, blockIndex),
                                  (() => {
                                    const block = props.definitionAST().pages[pageIndex].blocks?.[blockIndex];
                                    block.blocks = state.map(i => i.item);
                                    return block;
                                  })(),
                                  ...props.definitionAST().pages[pageIndex].blocks?.slice?.(blockIndex + 1),
                                ];
                                return page;
                              })(),
                              ...props.definitionAST().pages.slice(pageIndex + 1),
                            ],
                          });
                        }}
                        // @see https://sortablejs.github.io/Sortable/#nested
                        group="form-structure-blocks"
                        animation="150"
                        fallbackOnBody="true"
                        swapThreshold="0.65"
                        // @see https://sortablejs.github.io/Sortable/#handle
                        handle=".handle"
                        filter=".filtered"
                      >
                        {(block.blocks ?? []).map((subBlock, subBlockIndex) => (<>
                          <div
                            key={subBlockIndex}
                            className={css`
                              display: flex;
                              pointer-events: auto;
                              cursor: pointer;
                              ${true
                                && pageIndex == props.pageActiveIndex()
                                && blockIndex == props.blockActiveIndex()
                                && subBlockIndex == props.subBlockActiveIndex()
                                && `
                                  font-weight: 700;
                                `
                              }
                            `}
                            onClick={(e) => {
                              props.pageActiveIndex(pageIndex);
                              props.blockActiveIndex(blockIndex);
                              props.subBlockActiveIndex(subBlockIndex);
                            }}
                          >
                            <div
                              className={'handle ' + css`
                                display: grid;
                                place-content: center;
                                place-items: center;
                                width: 32px;
                                cursor: move;
                              `}
                            >
                              <IconDrag />
                            </div>
                            <div
                              className={css`
                                display: grid;
                                place-content: center;
                                place-items: center;
                              `}
                            >
                              <div>
                                Sub-Block {subBlockIndex + 1}
                                &nbsp;
                                <button
                                  className={css`
                                    display: inline-block;
                                    padding: 4px;
                                  `}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    const pageIndex = props.definitionAST().pages.findIndex(v => v === page);
                                    const blockIndex = props.definitionAST().pages[pageIndex].blocks.findIndex(v => v === block);
                                    props.definitionAST({
                                      ...props.definitionAST(),
                                      pages: [
                                        ...props.definitionAST().pages.slice(0, pageIndex),
                                        (() => {
                                          const page = props.definitionAST().pages[pageIndex];
                                          page.blocks = [
                                            ...props.definitionAST().pages[pageIndex].blocks?.slice?.(0, blockIndex),
                                            (() => {
                                              const block = props.definitionAST().pages[pageIndex].blocks?.[blockIndex];
                                              block.blocks = block.blocks.filter(v => v !== subBlock)
                                              return block;
                                            })(),
                                            ...props.definitionAST().pages[pageIndex].blocks?.slice?.(blockIndex + 1),
                                          ];
                                          return page;
                                        })(),
                                        ...props.definitionAST().pages.slice(pageIndex + 1),
                                      ],
                                    });
                                  }}
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </>))}
                      </ReactSortable>
                      {block.component == 'Group' && <>
                        <div>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              const pageIndex = props.definitionAST().pages.findIndex(v => v === page);
                              const blockIndex = props.definitionAST().pages[pageIndex].blocks.findIndex(v => v === block);
                              props.definitionAST(formDefinitionFillIds({
                                ...props.definitionAST(),
                                pages: [
                                  ...props.definitionAST().pages.slice(0, pageIndex),
                                  (() => {
                                    const page = props.definitionAST().pages[pageIndex];
                                    page.blocks = [
                                      ...props.definitionAST().pages[pageIndex].blocks?.slice?.(0, blockIndex),
                                      (() => {
                                        const block = props.definitionAST().pages[pageIndex].blocks?.[blockIndex];
                                        block.blocks.push({
                                          component: 'SingleLineTextInput',
                                          props: {},
                                          metadata: {},
                                        });
                                        return block;
                                      })(),
                                      ...props.definitionAST().pages[pageIndex].blocks?.slice?.(blockIndex + 1),
                                    ];
                                    return page;
                                  })(),
                                  ...props.definitionAST().pages.slice(pageIndex + 1),
                                ],
                              }));
                            }}
                          >
                            Add sub-block
                          </button>
                        </div>
                      </>}
                    </div>
                  </div>
                </>))}
              </ReactSortable>
              <div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const pageIndex = props.definitionAST().pages.findIndex(v => v === page);
                    props.definitionAST(formDefinitionFillIds({
                      ...props.definitionAST(),
                      pages: [
                        ...props.definitionAST().pages.slice(0, pageIndex),
                        (() => {
                          const page = props.definitionAST().pages[pageIndex];
                          page.blocks.push({
                            component: 'SingleLineTextInput',
                            props: {},
                            metadata: {},
                          });
                          return page;
                        })(),
                        ...props.definitionAST().pages.slice(pageIndex + 1),
                      ],
                    }));
                  }}
                >
                  Add block
                </button>
              </div>
            </div>
          </div>
        </>))}
      </ReactSortable>
      <div>
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.definitionAST(formDefinitionFillIds({
              ...props.definitionAST(),
              pages: [
                ...props.definitionAST().pages,
                {
                  blocks: [],
                },
              ],
            }));
          }}
        >
          Add page
        </button>
      </div>
    </div>
  );
};

const BlockEdit = (props) => {

  const blockMetadata = useStateAtom([]);

  useEffect(() => {
    const newBlockMetadata = [
      ...Object.entries(props.block()?.metadata ?? {}).map(e => ({key: e[0], value: e[1]})).filter(e => !!e.key),
      {key: '', value: ''},
    ];
    if (JSON.stringify(blockMetadata()) == JSON.stringify(newBlockMetadata))
      return;
    blockMetadata(newBlockMetadata);
  }, [JSON.stringify(props.block())]);

  useEffect(() => {
    props.block({
      ...props.block(),
      metadata: Object.fromEntries(blockMetadata().filter(e => !!e.key).map(e => [e.key, e.value])),
    });
  }, [JSON.stringify(blockMetadata())]);

  const advancedSource = useStateAtom('');
  const advanced = useStateAtom('');

  useEffect(() => {
    if (JSON.stringify(advancedSource()) == JSON.stringify(yamlStringify(props.block())))
      return;
    advancedSource(yamlStringify(props.block()));
    advanced(yamlStringify(props.block()));
  }, [JSON.stringify(props.block())]);

  return (<>
    <div
      className={css`
        display: flex;
        flex-direction: column;
        gap: 8px;
      `}
    >
      <div>
        {(() => {
          if (!props.block()?.component)
            return (<></>);
          return (<>
            <label
              className={css`
                display: flex;
                gap: 8px;
                padding: 8px;
                border-style: solid;
                border-width: 1px;
                border-color: #d1d5db;
              `}
            >
              <div
                className={css`
                  display: grid;
                  place-content: start center;
                  place-items: start center;
                `}
              >
                Type
              </div>
              <div
                className={css`
                  display: grid;
                  place-content: stretch;
                  place-items: center;
                  width: 100%;
                `}
              >
                <ReactSelect
                  styles={{
                    container: (baseStyles, state) => ({
                      ...baseStyles,
                      width: '100%',
                    }),
                  }}
                  value={{label: props.block()?.component ?? '', value: props.block()?.component ?? ''}}
                  onChange={(val) => {
                    props.block({
                      ...props.block(),
                      component: val.value,
                    });
                  }}
                  options={[
                    {value: 'DropdownInput', label: 'DropdownInput'},
                    ...(props.noGroup ? [] : [{value: 'Group', label: 'Group'}]),
                    {value: 'HTML', label: 'HTML'},
                    {value: 'MultiLineTextInput', label: 'MultiLineTextInput'},
                    {value: 'OpinionScaleInput', label: 'OpinionScaleInput'},
                    {value: 'SingleLineTextInput', label: 'SingleLineTextInput'},
                  ]}
                />
              </div>
            </label>
          </>);
        })()}
      </div>
      <div>
        {(() => {
          if (!props.block()?.component || props.block()?.component == 'Group' || props.block()?.component == 'HTML')
            return (<></>);
          return (<>
            <label
              className={css`
                display: flex;
                gap: 8px;
                padding: 8px;
                border-style: solid;
                border-width: 1px;
                border-color: #d1d5db;
              `}
            >
              <div
                className={css`
                  display: grid;
                  place-content: start center;
                  place-items: start center;
                `}
              >
                Label
              </div>
              <div
                className={css`
                  display: grid;
                  place-content: stretch;
                  place-items: center;
                  width: 100%;
                `}
              >
                <input
                  type="text"
                  className={css`
                    width: 100%;
                  `}
                  value={props.block()?.props?.label ?? []}
                  onChange={(e) => {
                    props.block({
                      ...props.block(),
                      props: {
                        ...props.block()?.props,
                        label: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            </label>
          </>);
        })()}
      </div>
      <div>
        {(() => {
          if (props.block()?.component == 'DropdownInput')
            return (<>
              <label
                className={css`
                  display: flex;
                  gap: 8px;
                  padding: 8px;
                  border-style: solid;
                  border-width: 1px;
                  border-color: #d1d5db;
                `}
              >
                <div
                  className={css`
                    display: grid;
                    place-content: start center;
                    place-items: start center;
                  `}
                >
                  Choices
                </div>
                <div
                  className={css`
                    display: grid;
                    place-content: stretch;
                    place-items: center;
                    width: 100%;
                  `}
                >
                  <ReactSelectCreatable
                    styles={{
                      container: (baseStyles, state) => ({
                        ...baseStyles,
                        width: '100%',
                      }),
                    }}
                    isMulti
                    value={props.block()?.props?.choices?.map?.(c => ({label: c, value: c})) ?? []}
                    onChange={(val) => {
                      props.block({
                        ...props.block(),
                        props: {
                          ...props.block()?.props,
                          choices: val.map(v => v.value),
                        },
                      });
                    }}
                  />
                </div>
              </label>
            </>);
          if (props.block()?.component == 'HTML')
            return (<>
              <label
                className={css`
                  display: flex;
                  gap: 8px;
                  padding: 8px;
                  border-style: solid;
                  border-width: 1px;
                  border-color: #d1d5db;
                `}
              >
                <div
                  className={css`
                    display: grid;
                    place-content: start center;
                    place-items: start center;
                  `}
                >
                  HTML
                </div>
                <div
                  className={css`
                    display: grid;
                    place-content: stretch;
                    place-items: center;
                    width: 100%;
                  `}
                >
                  <ReactQuill
                    className={css`
                      width: 100%;
                    `}
                    // https://github.com/zenoamaro/react-quill?tab=readme-ov-file#props
                    theme="snow"
                    value={props.block()?.props?.html ?? ''}
                    onChange={(html) => {
                      props.block({
                        ...props.block(),
                        props: {
                          ...props.block()?.props,
                          html: html,
                        },
                      });
                    }}
                    modules={{
                      toolbar: [
                        // https://quilljs.com/docs/modules/toolbar/
                        [{ header: [1, 2, 3, false] }],
                        [{ 'size': ['small', false, 'large'] }],

                        ['bold', 'italic', 'underline'],
                        [{ 'color': [] }],
                        // [{ 'size': ['small', false, 'large', 'huge'] }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'indent': '-1'}, { 'indent': '+1' }], // outdent/indent
                        ['link'], // insert/edit hyperlink
                        [{ 'align': [] }], // align left/center/right
                        // ['image'], // attach image
                        // ['code', 'code-block'],
                        ['blockquote'],
                        ['clean'], // remove formatting button
                      ],
                    }}
                    formats={[
                      // https://quilljs.com/docs/formats/
                      'header',
                      'size',
                      'bold', 'italic', 'underline',
                      'color',
                      'list',
                      'indent',
                      'link',
                      'align',
                      'image',
                      // 'code',
                      'blockquote',
                    ]}
                  />
                </div>
              </label>
            </>);
        })()}
      </div>
      <div>
        {(() => {
          if (!props.block()?.component || props.block()?.component == 'Group' || props.block()?.component == 'HTML')
            return (<></>);
          return (<>
            <label
              className={css`
                display: flex;
                gap: 8px;
                padding: 8px;
                border-style: solid;
                border-width: 1px;
                border-color: #d1d5db;
              `}
            >
              <div
                className={css`
                  display: grid;
                  place-content: start center;
                  place-items: start center;
                `}
              >
                Key
              </div>
              <div
                className={css`
                  display: grid;
                  place-content: stretch;
                  place-items: center;
                  width: 100%;
                `}
              >
                <input
                  type="text"
                  className={css`
                    width: 100%;
                  `}
                  value={props.block()?.props?.key ?? []}
                  onChange={(e) => {
                    props.block({
                      ...props.block(),
                      props: {
                        ...props.block()?.props,
                        key: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            </label>
          </>);
        })()}
      </div>
      <div>
        {(() => {
          if (!props.block()?.component || props.block()?.component == 'Group' || props.block()?.component == 'HTML')
            return (<></>);
          return (<>
            <label
              className={css`
                display: flex;
                gap: 8px;
                padding: 8px;
                border-style: solid;
                border-width: 1px;
                border-color: #d1d5db;
              `}
            >
              <div
                className={css`
                  display: grid;
                  place-content: center;
                  place-items: center;
                `}
              >
                <input
                  type="checkbox"
                  checked={props.block()?.props?.required ?? false}
                  onChange={(e) => {
                    props.block({
                      ...props.block(),
                      props: {
                        ...props.block()?.props,
                        required: !(props.block()?.props?.required ?? false),
                      },
                    });
                  }}
                />
              </div>
              <div
                className={css`
                  display: grid;
                  place-content: center;
                  place-items: center;
                `}
              >
                Required
              </div>
            </label>
          </>);
        })()}
      </div>
      <div>
        {(() => {
          if (props.block()?.component != 'Group')
            return (<></>);
          return (<>
            <label
              className={css`
                display: flex;
                gap: 8px;
                padding: 8px;
                border-style: solid;
                border-width: 1px;
                border-color: #d1d5db;
              `}
            >
              <div
                className={css`
                  display: grid;
                  place-content: center;
                  place-items: center;
                `}
              >
                <input
                  type="checkbox"
                  checked={props.block()?.shuffle ?? props.block()?.props?.shuffle ?? false}
                  onChange={(e) => {
                    props.block({
                      ...props.block(),
                      shuffle: undefined,
                      props: {
                        ...props.block()?.props,
                        shuffle: !(props.block()?.shuffle ?? props.block()?.props?.shuffle ?? false),
                      },
                    });
                  }}
                />
              </div>
              <div
                className={css`
                  display: grid;
                  place-content: center;
                  place-items: center;
                `}
              >
                Shuffle
              </div>
            </label>
          </>);
        })()}
      </div>
      <div>
        {(() => {
          if (props.block()?.component != 'OpinionScaleInput')
            return (<></>);
          return (<>
            <label
              className={css`
                display: flex;
                gap: 8px;
                padding: 8px;
                border-style: solid;
                border-width: 1px;
                border-color: #d1d5db;
              `}
            >
              <div
                className={css`
                  display: grid;
                  place-content: center;
                  place-items: center;
                `}
              >
                <input
                  type="checkbox"
                  checked={props.block()?.props?.reverseValue ?? false}
                  onChange={(e) => {
                    props.block({
                      ...props.block(),
                      props: {
                        ...props.block()?.props,
                        reverseValue: !(props.block()?.props?.reverseValue ?? false),
                      },
                    });
                  }}
                />
              </div>
              <div
                className={css`
                  display: grid;
                  place-content: center;
                  place-items: center;
                `}
              >
                Reverse Value
              </div>
            </label>
          </>);
        })()}
      </div>
      <div>
        {(() => {
          if (!props.block()?.component || props.block()?.component == 'Group' || props.block()?.component == 'HTML')
            return (<></>);
          return (<>
            <div
              className={css`
                padding: 8px;
                border-style: solid;
                border-width: 1px;
                border-color: #d1d5db;
              `}
            >
              Metadata
              {blockMetadata().map((metadataEntry, metadataEntryIndex) => (<>
                <div
                  key={metadataEntryIndex}
                  className={css`
                    display: flex;
                    gap: 8px;
                    margin-top: 8px;
                  `}
                >
                  <div
                    className={css`
                      flex: none;
                      width: 256px;
                      display: grid;
                      place-content: stretch;
                      place-items: center;
                    `}
                  >
                    <input
                      className={css`
                        width: 100%;
                      `}
                      type="text"
                      value={String(metadataEntry.key)}
                      onChange={(e) => {
                        blockMetadata([
                          ...blockMetadata().slice(0, metadataEntryIndex),
                          {
                            ...blockMetadata()[metadataEntryIndex],
                            key: e.target.value,
                          },
                          ...blockMetadata().slice(metadataEntryIndex + 1),
                        ]);
                      }}
                    />
                  </div>
                  <div
                    className={css`
                      flex: 1 1 0%;
                      display: grid;
                      place-content: stretch;
                      place-items: center;
                    `}
                  >
                    <input
                      className={css`
                        width: 100%;
                      `}
                      type="text"
                      value={metadataEntry.value}
                      onChange={(e) => {
                        blockMetadata([
                          ...blockMetadata().slice(0, metadataEntryIndex),
                          {
                            ...blockMetadata()[metadataEntryIndex],
                            value: e.target.value,
                          },
                          ...blockMetadata().slice(metadataEntryIndex + 1),
                        ]);
                      }}
                    />
                  </div>
                </div>
              </>))}
            </div>
          </>);
        })()}
      </div>
      <div
        className={css`
          padding: 8px;
          border-style: solid;
          border-width: 1px;
          border-color: #d1d5db;
        `}
      >
        <label
          className={css`
            display: flex;
            gap: 8px;
          `}
        >
          <div
            className={css`
              display: grid;
              place-content: start center;
              place-items: start center;
            `}
          >
            Advanced
          </div>
          <div
            className={css`
              display: grid;
              place-content: stretch;
              place-items: center;
              width: 100%;
              overflow: hidden;
            `}
          >
            <textarea
              className={css`
                width: 100%;
                height: 16px;
                resize: vertical;
              `}
              value={advanced()}
              onChange={(e) => {
                //props.block(yamlParse(e.target.value));
                advanced(e.target.value);
              }}
              //onBlur={(e) => {
              //  props.block(yamlParse(advanced()));
              //}}
            >{yamlStringify(props.block())}</textarea>
          </div>
        </label>
        <div
          className={css`
            text-align: right;
          `}
          onClick={(e) => {
            props.block(yamlParse(advanced()));
          }}
        >
          <button>Update</button>
        </div>
      </div>
    </div>
  </>);

};

const IconDrag = (props) => {
  return (<>
    <svg
      className={css`
        display: inline-block;
        width: 1em;
        height: 1em;
        vertical-align: baseline;
        fill: currentColor;
      `}
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 18.005C7 19.1078 7.9 20 9 20C10.1 20 11 19.1078 11 18.005C11 16.9023 10.1 16 9 16C7.9 16 7 16.9023 7 18.005Z" fill="#000000"/>
      <path d="M7 12.005C7 13.1078 7.9 14 9 14C10.1 14 11 13.1078 11 12.005C11 10.9023 10.1 10 9 10C7.9 10 7 10.9023 7 12.005Z" fill="#000000"/>
      <path d="M7 6.00501C7 7.10777 7.9 8 9 8C10.1 8 11 7.10777 11 6.00501C11 4.90226 10.1 4 9 4C7.9 4 7 4.90226 7 6.00501Z" fill="#000000"/>
      <path d="M13 6.00501C13 7.10777 13.9 8 15 8C16.1 8 17 7.10777 17 6.00501C17 4.90226 16.1 4 15 4C13.9 4 13 4.90226 13 6.00501Z" fill="#000000"/>
      <path d="M13 12.005C13 13.1078 13.9 14 15 14C16.1 14 17 13.1078 17 12.005C17 10.9023 16.1 10 15 10C13.9 10 13 10.9023 13 12.005Z" fill="#000000"/>
      <path d="M13 18.005C13 19.1078 13.9 20 15 20C16.1 20 17 19.1078 17 18.005C17 16.9023 16.1 16 15 16C13.9 16 13 16.9023 13 18.005Z" fill="#000000"/>
    </svg>
  </>);
};
