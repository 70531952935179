
import React from 'react';

import ajaxAdmin from '../../src/pi3-frontend/ajax-admin.js';
import {css} from 'emotion';
import {useFirstRender} from '../../src/utilities/React.js';
import {useStateAtom} from '../../src/utilities/React/UseStateAtom.js';
import {utils as xlsxUtils}  from 'xlsx';
import {writeFile as xlsxWriteFile}  from 'xlsx';
import {yamlParse} from '../../src/utilities/yaml/yamlParse.js';
import {yamlStringify} from '../../src/utilities/yaml/yamlStringify.js';

export default function (props) {

  const formInfo = useStateAtom(null);
  const definitionAST = useStateAtom(null);
  const records = useStateAtom(null);
  const recordActive = useStateAtom(null);

  useFirstRender(async () => {
    formInfo((await ajaxAdmin.adminFormRecords(props.formId)).form);
    definitionAST(yamlParse(formInfo().definitionScript));
    records(formInfo().records);
  });

  const recordsToTableData = () => {

    const valueCleanup = (val) => {
      return String(val)
        .replace(/\<[^>]*\>/sg, '')
        .replace(/&nbsp;/sg, ' ')
        .replace(/[\r\n\t ]+/sg, ' ')
        .trim()
      ;
    };

    const columns = (() => {
      const result = [];
      for (const page of definitionAST()?.pages ?? []) {
        for (const block of page?.blocks ?? []) {
          if (block.component == 'Group') {
            for (const subBlock of block?.blocks ?? []) {
              result.push({
                id: subBlock.id,
                column: valueCleanup(subBlock.props?.key ?? subBlock.props?.label ?? subBlock.props?.html),
              });
            }
            continue;
          }
          result.push({
            id: block.id,
            column: valueCleanup(block.props?.key ?? block.props?.label ?? block.props?.html),
          });
        }
      }
      return result;
    })();

    const rows = records().map((record) => {
      return columns.map(c => {
        const answer = record.answers.find(a => a.id == c.id);
        return {
          id: c.id,
          column: c.column,
          value: valueCleanup(answer?.value ?? ''),
        };
      });
    });

    return {
      name: String(formInfo().name).replace(/[^A-Za-z0-9\_\-]+/sg, '-'),
      date: ((new Date()).toISOString()).substr(0, 10),
      columns: columns,
      rows: rows,
    };

  };

  return (<>

    <div>

      <button
        onClick={(e) => {

          const tableData = recordsToTableData();

          const csvQuote = (val) => '"' + String(val).replace(/"/sg, '""') + '"';

          // @see https://www.stefanjudis.com/snippets/how-trigger-file-downloads-with-javascript/
          const file = new File(
            [
              tableData.columns.map(c => csvQuote(c.column)).join(',')
              + "\n"
              + tableData.rows.map(r => r.map(v => csvQuote(v.value)).join(',')).join("\n")
            ],
            `form-records-${tableData.name}-${tableData.date}.csv`,
          );
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = URL.createObjectURL(file);
          link.download = file.name;
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            URL.revokeObjectURL(link.href);
            link.parentNode.removeChild(link);
          }, 100);

        }}
      >
        Download CSV
      </button>

      &nbsp;

      <button
        onClick={(e) => {

          const tableData = recordsToTableData();

          const wb = xlsxUtils.book_new();
          xlsxUtils.book_append_sheet(
            wb,
            xlsxUtils.json_to_sheet(
              tableData.rows.map(row => Object.fromEntries(row.map(entry => [entry.column, entry.value])))
            ),
            'Form Records',
          );
          xlsxWriteFile(wb, `form-records-${tableData.name}-${tableData.date}.xlsx`);

        }}
      >
        Download XLSX
      </button>

    </div>

    <div
      className={css`
        width: 100%;
        height: 100vh;
        font-size: 16px;
      `}
    >
      <div
        className={css`
          display: flex;
          width: 100%;
          height: 100%;
        `}
      >
        <div
          className={css`
            flex: none;
            width: 300px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 5px;
          `}
        >
          {records() && records().map((record) => (<>
            <div
              className={css`
                cursor: pointer;
                ${recordActive() == record.id ? 'text-decoration: underline;' : ''}
              `}
              onClick={(e) => {
                recordActive(record.id);
              }}
            >
              {(new Date(record.createdAt)).toUTCString()}
            </div>
          </>))}
        </div>
        <div>
          {records() && records().map((record) => (<>
            {recordActive() == record.id && (<>
              <div>
                <pre>
                  {/*yamlStringify(record.answers.map(a => ({...a, quillFormsData: undefined})))/**/}
                  {yamlStringify(record.answers.map(a => ({
                    id: a.id,
                    key: a.props?.key ?? a.props?.label ?? a.props?.html,
                    question: a.props?.label ?? '',
                    answer: a.value,
                    metadata: a.metadata,
                  })))}
                </pre>
              </div>
            </>)}
          </>))}

        </div>
      </div>
    </div>

  </>);

};
