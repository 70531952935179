
export const sandboxCompileFunction = (fnCode) => {

  if (typeof(fnCode) != 'string')
    return {
      ok: () => {
        throw new Error('Code provided to `sandboxCompileFunction` is not a function source code.');
      },
      err: () => {
        return new Error('Code provided to `sandboxCompileFunction` is not a function source code.');
      },
    };

  try {

    const resultFn = eval(`
      (() => {
        return ${String(fnCode).trim()};
      })()
    `);

    if (typeof(resultFn) != 'function')
      return {
        ok: () => {
          throw new Error('Code provided to `sandboxCompileFunction` does not compile to a function.');
        },
        err: () => {
          return new Error('Code provided to `sandboxCompileFunction` does not compile to a function.');
        },
      };

    return {
      ok: () => resultFn,
      err: () => null,
    };

  } catch (err) {

    return {
      ok: () => {
        throw new Error('Code provided to `sandboxCompileFunction` does not compile to a function.', err);
      },
      err: () => {
        return err;
      },
    };

  }

};
