
import React from 'react';

import ajaxAdmin from '../../src/pi3-frontend/ajax-admin.js';
import ReactSelect from 'react-select';
import {arrayMedian} from '../../src/libs/array/arrayMedian.js';
import {Chart as ChartJS} from 'chart.js/auto';
import {css} from 'emotion';
import {sandboxCompileFunction} from '../../src/libs/sandbox/sandboxCompileFunction.js';
import {useEffect} from 'react';
import {useFirstRender} from '../../src/utilities/React.js';
import {useRef} from 'react';
import {useStateAtom} from '../../src/utilities/React/UseStateAtom.js';
import {utils as xlsxUtils}  from 'xlsx';
import {writeFile as xlsxWriteFile}  from 'xlsx';
import {yamlParse} from '../../src/utilities/yaml/yamlParse.js';
import {yamlStringify} from '../../src/utilities/yaml/yamlStringify.js';

export default function (props) {

  const formInfo = useStateAtom(null);
  const definitionAST = useStateAtom(null);
  const records = useStateAtom(null);
  const recordActive = useStateAtom(null);
  const tableData = useStateAtom(null);
  const recordsView = useStateAtom(null);
  const recordsFiltersActive = useStateAtom([]);
  const answersFiltersActive = useStateAtom([]);
  const answersDimensionsActive = useStateAtom([]);
  const answersMetricsActive = useStateAtom([]);

  const recordsToTableData = () => {

    const valueCleanup = (val) => {
      return String(val)
        .replace(/\<[^>]*\>/sg, '')
        .replace(/&nbsp;/sg, ' ')
        .replace(/[\r\n\t ]+/sg, ' ')
        .trim()
      ;
    };

    const columns = (() => {
      const result = [];
      for (const page of definitionAST()?.pages ?? []) {
        for (const block of page?.blocks ?? []) {
          if (block.component == 'Group') {
            for (const subBlock of block?.blocks ?? []) {
              result.push({
                id: subBlock.id,
                column: valueCleanup(subBlock.props?.key ?? subBlock.props?.label ?? subBlock.props?.html),
                block: subBlock,
              });
            }
            continue;
          }
          result.push({
            id: block.id,
            column: valueCleanup(block.props?.key ?? block.props?.label ?? block.props?.html),
            block: block,
          });
        }
      }
      return result;
    })();

    const rows = records().map((record) => {
      return columns.map(c => {
        const answer = record.answers.find(a => a.id == c.id);
        return {
          id: c.id,
          column: c.column,
          value: valueCleanup(answer?.value ?? ''),
          answer: answer,
        };
      });
    });

    return {
      name: String(formInfo().name).replace(/[^A-Za-z0-9\_\-]+/sg, '-'),
      date: ((new Date()).toISOString()).substr(0, 10),
      columns: columns,
      rows: rows,
    };

  };

  useFirstRender(async () => {
    formInfo((await ajaxAdmin.adminFormRecords(props.formId)).form);
    definitionAST(yamlParse(formInfo().definitionScript));
    records(formInfo().records);
  });

  useEffect(() => {
    if (!definitionAST())
      return;
    recordsView(sandboxCompileFunction({
      sourceCode: definitionAST().recordsViewCode || '() => ({})',
      symbols: {
        arrayMedian: arrayMedian,
      },
    }).ok()());
  }, [definitionAST()]);

  useEffect(() => {
    if (!recordsView()?.recordsFilters)
      return;
    records(formInfo().records.filter(record => {
      for (const recordFilter of (recordsView()?.recordsFilters ?? [])) {
        if (!recordsFiltersActive().includes(recordFilter.id || recordFilter.label))
          continue;
        if (!recordFilter.filterFn({record: record}))
          return false;
      }
      return true;
    }));
  }, [formInfo(), recordsView(), recordsFiltersActive()]);

  useEffect(() => {
    if (!records())
      return;
    tableData(recordsToTableData());
  }, [records()]);

  return (<>

    {(recordsView()?.recordsFilters ?? []).length > 0 && (<>
      <div
        className={css`
          margin-top: 16px;
        `}
      >
        <div>
          Select records filters (all records that do not match all of the filters are excluded):
        </div>
        <ReactSelect
          styles={{
            container: (baseStyles, state) => ({
              ...baseStyles,
              width: '100%',
            }),
          }}
          isMulti
          value={recordsFiltersActive().map?.(a => ({label: a, value: a})) ?? []}
          onChange={(val) => {
            recordsFiltersActive(val.map(v => v.value));
          }}
          options={(recordsView()?.recordsFilters ?? []).map(a => ({value: a.id || a.label, label: a.label}))}
        />
      </div>
    </>)}

    {(recordsView()?.answersFilters ?? []).length > 0 && (<>
      <div
        className={css`
          margin-top: 16px;
        `}
      >
        <div>
          Select answers filters (all answers that do not match all of the filters are excluded):
        </div>
        <ReactSelect
          styles={{
            container: (baseStyles, state) => ({
              ...baseStyles,
              width: '100%',
            }),
          }}
          isMulti
          value={answersFiltersActive().map?.(a => ({label: a, value: a})) ?? []}
          onChange={(val) => {
            answersFiltersActive(val.map(v => v.value));
          }}
          options={(recordsView()?.answersFilters ?? []).map(a => ({value: a.id || a.label, label: a.label}))}
        />
      </div>
    </>)}

    {(recordsView()?.answersDimensions ?? []).length > 0 && (<>
      <div
        className={css`
          margin-top: 16px;
        `}
      >
        <div>
          Select dimensions (you can select multiple dimensions, number of dimension and order of dimensions matters!):
        </div>
        <ReactSelect
          styles={{
            container: (baseStyles, state) => ({
              ...baseStyles,
              width: '100%',
            }),
          }}
          isMulti
          value={answersDimensionsActive().map?.(a => ({label: a, value: a})) ?? []}
          onChange={(val) => {
            answersDimensionsActive(val.map(v => v.value));
          }}
          options={(recordsView()?.answersDimensions ?? []).map(a => ({value: a.id || a.label, label: a.label}))}
        />
      </div>
    </>)}

    {(recordsView()?.answersMetrics ?? []).length > 0 && (<>
      <div
        className={css`
          margin-top: 16px;
        `}
      >
        <div>
          Select metrics (you can select multiple metrics):
        </div>
        <ReactSelect
          styles={{
            container: (baseStyles, state) => ({
              ...baseStyles,
              width: '100%',
            }),
          }}
          isMulti
          value={answersMetricsActive().map?.(a => ({label: a, value: a})) ?? []}
          onChange={(val) => {
            answersMetricsActive(val.map(v => v.value));
          }}
          options={(recordsView()?.answersMetrics ?? []).map(a => ({value: a.id || a.label, label: a.label}))}
        />
      </div>
    </>)}

    <div
      className={css`
        margin-top: 16px;
      `}
    >

      <button
        onClick={(e) => {

          const csvQuote = (val) => '"' + String(val).replace(/"/sg, '""') + '"';

          // @see https://www.stefanjudis.com/snippets/how-trigger-file-downloads-with-javascript/
          const file = new File(
            [
              tableData().columns.map(c => csvQuote(c.column)).join(',')
              + "\n"
              + tableData().rows.map(r => r.map(v => csvQuote(v.value)).join(',')).join("\n")
            ],
            `form-records-${tableData().name}-${tableData().date}.csv`,
          );
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = URL.createObjectURL(file);
          link.download = file.name;
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            URL.revokeObjectURL(link.href);
            link.parentNode.removeChild(link);
          }, 100);

        }}
      >
        Download CSV
      </button>

      &nbsp;

      <button
        onClick={(e) => {

          const wb = xlsxUtils.book_new();
          xlsxUtils.book_append_sheet(
            wb,
            xlsxUtils.json_to_sheet(
              tableData().rows.map(row => Object.fromEntries(row.map(entry => [entry.column, entry.value])))
            ),
            'Form Records',
          );
          xlsxWriteFile(wb, `form-records-${tableData().name}-${tableData().date}.xlsx`);

        }}
      >
        Download XLSX
      </button>

    </div>

    <div
      className={css`
        margin-top: 32px;
      `}
    >
      <RecordsChart
        recordsView={recordsView()}
        records={records()}
        filters={answersFiltersActive()}
        dimensions={answersDimensionsActive()}
        metrics={answersMetricsActive()}
        tableData={tableData()}
      />
    </div>

    <div
      className={css`
        margin-top: 32px;
        position: relative;
        width: 100%;
        max-height: 500px;
        font-size: 16px;
        overflow: auto;
      `}
    >
      <div
        className={css`
          display: flex;
          width: 100%;
          height: 100%;
        `}
      >
        <div
          className={css`
            flex: none;
            width: 300px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 5px;
          `}
        >
          {records() && records().map((record) => (<>
            <div
              className={css`
                cursor: pointer;
                ${recordActive() == record.id ? 'text-decoration: underline;' : ''}
              `}
              onClick={(e) => {
                recordActive(record.id);
              }}
            >
              {(new Date(record.createdAt)).toUTCString()}
            </div>
          </>))}
        </div>
        <div>
          {records() && records().map((record) => (<>
            {recordActive() == record.id && (<>
              <div>
                <pre>
                  {/*yamlStringify(record.answers.map(a => ({...a, quillFormsData: undefined})))/**/}
                  {yamlStringify(record.answers.map(a => ({
                    id: a.id,
                    key: a.props?.key ?? a.props?.label ?? a.props?.html,
                    question: a.props?.label ?? '',
                    answer: a.value,
                    metadata: a.metadata,
                  })))}
                </pre>
              </div>
            </>)}
          </>))}

        </div>
      </div>
    </div>

  </>);

};

const RecordsChart = (props) => {

  const canvasRef = useRef(null);
  const chart = useStateAtom(null);

  useEffect(() => {

    if (chart())
      chart().destroy();

    if (!canvasRef.current || !props.recordsView || !props.records || !props.tableData)
      return;

    if (!props.recordsView.singleChartJS)
      return;

    const chartData = props.recordsView.singleChartJS({
      recordsView: props.recordsView,
      records: props.records,
      filters: props.filters,
      dimensions: props.dimensions,
      metrics: props.metrics,
      tableData: props.tableData,
      arrangeAnswersIntoDimensions: (options) => {
        const primaryDimension = [];
        const datasets = {};
        const activeFilters = (options.filters ?? []).map(
          filterID => (props.recordsView.answersFilters ?? [])
            .find(f => (f.id || f.label) == filterID)
        );
        for (const record of options.records) {
          for (const answer of record.answers) {
            if (activeFilters.filter(f => f.filterFn({answer: answer, record: record})).length < activeFilters.length)
              continue;
            const dimensionsIDs = options.dimensions.map(
              dimensionID => (props.recordsView.answersDimensions ?? [])
                .find(d => (d.id || d.label) == dimensionID)
                .dimensionIDFn({
                  answer: answer,
                  record: record,
                })
            );
            if (dimensionsIDs.filter(d => (d ?? null) !== null).length < dimensionsIDs.length)
              continue;
            const datasetID = dimensionsIDs.slice(1).join('/') || '(all)';
            const primaryDimensionID = dimensionsIDs.slice(0, 1)[0];
            if (!datasets[datasetID])
              datasets[datasetID] = {
                id: datasetID,
                label: dimensionsIDs.slice(1).join(' / ') || '(all)',
                answersDimensioned: [],
              };
            if (!primaryDimension.find(v => v.id == primaryDimensionID))
              primaryDimension.push({
                id: primaryDimensionID,
                label: primaryDimensionID,
              });
            while (datasets[datasetID].answersDimensioned.length < primaryDimension.length)
              datasets[datasetID].answersDimensioned.push({
                id: primaryDimension[datasets[datasetID].answersDimensioned.length].id,
                label: primaryDimension[datasets[datasetID].answersDimensioned.length].label,
                answers: [],
              });
            datasets[datasetID].answersDimensioned.find(v => v.id == primaryDimensionID).answers.push(answer);
          }
        }
        return {
          primaryDimension: primaryDimension,
          datasets: Object.values(datasets),
        };
      },
      applyMetricsToDatasets: (options) => {
        const metricsDatasets = [];
        const activeMetrics = options.metrics.map(
          metricID => (props.recordsView.answersMetrics ?? [])
            .find(m => (m.id || m.label) == metricID)
        );
        for (const dataset of options.datasets)
          for (const answerMetric of activeMetrics)
            metricsDatasets.push({
              id: dataset.id + ' - ' + answerMetric.label,
              label: dataset.label + ' - ' + answerMetric.label,
              data: dataset.answersDimensioned
                .map(answerDimensioned => answerMetric.aggregateFn({answers: answerDimensioned.answers}) ?? undefined)
              ,
            });
        return metricsDatasets;
      },
    });

    chart(new ChartJS(canvasRef.current, {
      type: 'line',
      ...chartData,
      options: {
        responsive: true,
        ...chartData.options,
      },
    }));

  }, [canvasRef, props.recordsView, props.records, props.filters, props.dimensions, props.metrics, props.tableData]);

  // @see https://stackoverflow.com/questions/64139924/useeffect-hook-on-component-unmount/64140271#64140271
  const onUnmount = useRef();
  onUnmount.current = () => {
    if (!chart())
      return;
    chart().destroy();
  };
  useEffect(() => {
    return () => onUnmount.current();
  }, []);

  return (<>
    <div>
      <canvas ref={canvasRef} />
    </div>
  </>);

};
