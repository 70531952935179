
'use strict';

import enums from '../../src/pi3-frontend/enums.js';
import React from 'react';
import {postLearnerActivity} from '../../src/pi3-frontend/ajax.js';

// Access token used to prevent cross-site scripting attacks.
//
// @see https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#security_concerns
const iFrameToken = (() => {
  const bits = 144;
  const bytes = Math.ceil(bits / Math.log2(36));
  const randomBytes = new Uint8Array(bytes);
  // `window.crypto` is a native browser function that needs to be accessed
  // without `window` when used from NodeJS.
  crypto.getRandomValues(randomBytes);
  // `window.btoa` is a native browser function that needs to be accessed
  // without `window` when used from NodeJS.
  return btoa([...randomBytes].reduce((c, b) => c + String.fromCharCode(b), ''))
    .replace(/[\+\/\=]/g, '').toLowerCase().substring(0, bytes)
  ;
})();


const beforeUnloadHandler = (e) => {
  // @see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
  e.preventDefault();
  e.returnValue = true;
};

class CourseBlockChatflow extends React.Component {
  constructor(props) {
    super(props);
    window.addEventListener("message", async (e) => {
      try {
        const msg = JSON.parse(e.data);
        if (msg?.token != iFrameToken)
          return;
        if (msg.data.start) {
          if (!window.__hasBeforeUnload) {
            window.__hasBeforeUnload = true;
            window.addEventListener('beforeunload', beforeUnloadHandler);
          }
          this.props.onStart?.();
        }
        if (msg.data.complete) {
          if (window.__hasBeforeUnload) {
            window.__hasBeforeUnload = false;
            window.removeEventListener('beforeunload', beforeUnloadHandler);
          }
          this.props.onComplete?.();
          try {
            console.log('[CourseBlockChatflow] Posting lesson complete...', msg);
            await postLearnerActivity(
              enums.UserActivity.Type.ChatflowComplete,
              props.lesson.id,
              msg.data.summary.maxPoints,
              msg.data.summary.totalPoints,
            );
            await postLearnerActivity(
              enums.UserActivity.Type.LessonComplete,
              props.lesson.id,
              msg.data.summary.maxPoints,
              msg.data.summary.totalPoints,
            );
          } catch (err) {
            console.error('[CourseBlockChatflow] Error posting lesson complete', err);
          }
        }
        if (msg.data.report) {
          try {
            console.log('[CourseBlockChatflow] Posting report complete...', msg);
            await postLearnerActivity(
              enums.UserActivity.Type.ChatflowReport,
              props.lesson.id,
              undefined,
              undefined,
              {
                chatflowId: block.html,
                question: msg.data.question,
                answer: msg.data.answer,
                feedback: msg.data.feedback,
              },
            );
          } catch (err) {
            console.error('[CourseBlockChatflow] Error posting report complete', err);
          }
        }
      } catch (err) {}
    });
  }
  render() {
    let block = this.props.block;

    // Wait until progress info is loaded
    if (!this.props.lessonProgress)
      return <div></div>;

    const resetActivity = !!location.search.match(/[\?\&]reset-activity(\=|$)/);

    return (
      <div
        className='pi3courseChatflow'
        id={this.props.id}
      >
        {!resetActivity && this.props.lessonProgress.isCompleted
          ?
            <div
              className="tw:border-0 tw:w-full tw:p-2 tw:bg-[#edeff6] tw:place-content-center tw:place-items-center"
              style={{
                height: '500px',
              }}
            >
              <div className="tw:h-auto tw:text-center">
                <img className="tw:w-16 tw:h-16" src="/static/images/cat-happy.svg" />
              </div>
              <div className="tw:h-auto tw:text-center tw:text-[#5a5d65] tw:text-base tw:font-bold tw:font-['Poppins'] tw:uppercase tw:leading-9 tw:tracking-wider">
                Activity Complete!
              </div>
              <div className="tw:h-auto tw:pt-1.5 tw:text-center tw:text-[#75003a] tw:text-base tw:font-normal tw:font-['Poppins'] tw:leading-tight">
                You scored a total of {this.props.lessonProgress.quizCorrect}/{this.props.lessonProgress.quizTotal} points.
              </div>
              <div className="tw:h-auto tw:pt-1.5 tw:text-center tw:text-[#5a5d65] tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">
                You’ve already completed this activity.
              </div>
            </div>
          :
            <iframe
              className="tw:border-0 tw:w-full"
              style={{
                height: '500px',
              }}
              src={`${__CHATFLOW_API_URL__}/chatflow-pi/${encodeURIComponent(block.html)}?-iframe-token=${encodeURIComponent(iFrameToken)}`}
            />
        }
      </div>
    );
  }
}

export default CourseBlockChatflow;
