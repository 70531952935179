
'use strict';

import React from 'react';
import ReactDOM from 'react-dom';


class CourseBlockImage extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let block = this.props.block;
    let alt = this.props.alt;
    return (
      <div className='pi3courseImage' id={this.props.id}>
        <img
          src={block.url}
          alt={alt}
          />
      </div>
    );
  }
}

export default CourseBlockImage;