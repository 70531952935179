
export const formDefinitionFillIds = (formDefinition) => {
  const idMap = {};
  for (const pageIndex in formDefinition?.pages ?? []) {
    const page = formDefinition.pages[pageIndex];
    if (page.id)
      idMap[page.id] = true;
    for (const blockIndex in page.blocks) {
      const block = page.blocks[blockIndex];
      if (block.id)
        idMap[block.id] = true;
      for (const subBlockIndex in block.blocks) {
        const subBlock = block.blocks[subBlockIndex];
        if (subBlock.id)
          idMap[subBlock.id] = true;
      }
    }
  }
  const nextId = (idCandidate) => {
    if (!idMap[idCandidate]) {
      idMap[idCandidate] = true;
      return idCandidate;
    }
    for (const i = 0;; i += 1) {
      if (idMap[idCandidate + '_' + i])
        continue;
      idMap[idCandidate + '_' + i] = true;
      return idCandidate + '_' + i;
    }
  };
  const result = {
    pages: [],
  };
  for (const pageIndex in formDefinition?.pages ?? []) {
    const page = formDefinition.pages[pageIndex];
    const resultPage = {
      id: page.id ?? nextId(`page_${pageIndex}`),
      ...page,
      blocks: [],
    };
    for (const blockIndex in page.blocks) {
      const block = page.blocks[blockIndex];
      const resultBlock = {
        id: block.id ?? nextId(`answer_${pageIndex}_${blockIndex}`),
        ...block,
        blocks: [],
      };
      if (block.component == 'Group') {
        for (const subBlockIndex in block.blocks) {
          const subBlock = block.blocks[subBlockIndex];
          resultBlock.blocks.push({
            id: subBlock.id ?? nextId(`answer_${pageIndex}_${blockIndex}_${subBlockIndex}`),
            ...subBlock,
          });
        }
      }
      resultPage.blocks.push(resultBlock);
    }
    result.pages.push(resultPage);
  }
  return result;
};
