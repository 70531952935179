
export const sandboxCompileFunction = (options) => {

  if (typeof(options.sourceCode) != 'string')
    return {
      ok: () => {
        throw new Error('Code provided to `sandboxCompileFunction` is not a function source code.');
      },
      err: () => {
        return new Error('Code provided to `sandboxCompileFunction` is not a function source code.');
      },
    };

  try {

    const resultFn = eval(`
      (() => {
        ${Object.keys(options?.symbols ?? {}).map(k => `const ${k} = options.symbols.${k};`).join("\n")};
        return ${String(options.sourceCode).trim()};
      })()
    `);

    if (typeof(resultFn) != 'function')
      return {
        ok: () => {
          throw new Error('Code provided to `sandboxCompileFunction` does not compile.');
        },
        err: () => {
          return new Error('Code provided to `sandboxCompileFunction` does not compile.');
        },
      };

    return {
      ok: () => resultFn,
      err: () => null,
    };

  } catch (err) {

    return {
      ok: () => {
        throw new Error('Code provided to `sandboxCompileFunction` does not compile.', err);
      },
      err: () => {
        return err;
      },
    };

  }

};
