import React from 'react';

import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import { getQueryAttribute, shallowCopy, toPrettyDollarsRound, toHumanArray } from './helpers';

import { emitPageView, emitIndividualPurchaseComplete, emitEvent } from './analytics';

import {
  getUserInfo,
  getProductsV4,
  validateEmail,
  purchaseNew,
  getCheckoutSession,
} from './ajax';

import validation from './validation';
import enums from './enums';

import Image from './Image';
import LoadingCenter from './LoadingCenter';
import { FormCheckbox } from './FormElements';


// eslint-disable-next-line no-undef
const STRIPE_PUBKEY = __STRIPE_PUBLISHABLE_KEY__;
const stripePromise = loadStripe(STRIPE_PUBKEY);


class PurchaseNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'loading', // 'loading', 'select', 'signup', 'checkout', 'complete', 
      cart: null,
      userForm: {
        nameFirst: '',
        nameLast: '',
        email: '',
        terms: false,
        marketing: false,
        validate: false,
      },
    };
    // load cart from window if available
    if (typeof window !== 'undefined') {
      this.state.cart = window.cart;
      console.log('[PurchaseReturn] Global cart found.');
    }
    this.navigate = this.navigate.bind(this);
    this.mergeUserForm = this.mergeUserForm.bind(this);
    this.validateUserForm = this.validateUserForm.bind(this);
    this.submit = this.submit.bind(this);
  }

  navigate(stage) {
    console.log(`[PurchaseNew] Navigating to stage...`, { stage });

    // google analytics
    emitPageView(`/checkout/individuals/${stage}`, `New Purchase - ${stage}`);

    this.setState({ stage });
  }

  mergeUserForm(newData) {
    let userForm = shallowCopy(this.state.userForm, newData);
    this.setState({
      userForm,
      userFormTree: this.validateUserForm(null, userForm)
    });
  }

  validateUserForm(attribute, newData) {
    // console.log('what are we merging?', { attribute, newData });
    let data = newData ? newData : this.state.userForm;
    // if attribute is provided, return empty string or CSS class to add to invalid field
    if (attribute) {
      if (this.state.userForm.validate) {
        // check whether attribute inside validation tree is valid, if not return 'invalid' CSS class
        if (this.state.userFormTree && this.state.userFormTree[attribute] && this.state.userFormTree[attribute].length > 0) {
          return 'invalid';
        }
        return '';
      } else {
        return '';
      }
    } else {
      // generate validation tree
      let tree = {
        nameFirst: validation.validateString(data.nameFirst) ? null : 'First Name is required.',
        nameLast: validation.validateString(data.nameLast) ? null : 'Last Name is required.',
        email: validation.validateEmail(data.email) ? null : 'E-mail address is required.',
      };
      return tree;
    }
  }

  async submit() {
    try {
      // console.log('[IndividualPurchaseFlow] Submitting user onboarding...');

      let userForm = this.state.userForm;

      let cartData = this.state.cart.getCart();
      let productIds = cartData.items.map(i => i.id);

      let data = {
        nameFirst: userForm.nameFirst,
        nameLast: userForm.nameLast,
        email: userForm.email,
        terms: userForm.terms,
        unsubscribeFromMarketing: !userForm.marketing,
        productIds,
      };

      if (!data.terms) {
        // console.log('User did not agree to terms, cannot submit this.');
        return;
      }

      this.setState({ stage: 'loading' });

      // create checkout session
      let response = await purchaseNew(data);
      // google analytics - page view
      emitPageView('/checkout/individuals/checkout', 'New Purchase - Checkout');
      // google analytics - begin checkout
      // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#begin_checkout
      let eventData = { 
        currency: 'USD',
        value: cartData.total / 100,
        items: cartData.items.map(i => {
          return {
            item_id: i.name,
            item_name: i.name,
            price: i.stripeProduct.price.amount/100,
            quantity: 1 };
        }),
      };
      emitEvent('begin_checkout', eventData);

      
      // set state to checkout mode
      this.setState({
        stage: 'checkout',
        stripeCheckoutSessionId: response.stripeCheckoutSessionId,
        stripeCheckoutClientSecret: response.stripeCheckoutClientSecret,
      });

    }
    catch(e) {
      console.error('[IndividualPurchaseFlow] Error in submit().', e);
    }
  }

  async componentDidMount() {
    try {
      // fetch user info
      let user = await getUserInfo();
      console.log('[PurchaseNew] Fetched user info.', user);

      // TODO add handling for company users - purchases through this flow not allowed?

      // fetch cart
      if (!this.state.cart) {
        console.error('[PurchaseNew] Cart not found.');
        return;
      }
      let cartData = this.state.cart.getCart();
      console.log('[PurchaseNew] Fetched cart data.', cartData);

      // fetch products
      let products = await getProductsV4();
      console.log(`[PurchaseNew] Fetched products.`, products);

      // check if 'complete' state
      let stage = getQueryAttribute(window.location.search, 's');
      if (stage === 'complete') {

        // parse other URL parameters
        // http://localhost:3000/purchase/new?s=complete&pids=12,3,7&session_id=cs_test_b17cFOWgaBCuPjaSk0Iqkw3IfNIAiyfkb9kgI0NmIYisWz48JohbcMjDVN
        let stripeCheckoutSessionId = getQueryAttribute(window.location.search, 'session_id');
        let stripeCheckoutSession = await getCheckoutSession(stripeCheckoutSessionId);
        let productIds = getQueryAttribute(window.location.search, 'pids').split(',').map(id => parseInt(id));
        let purchasedProducts = products.filter(p => productIds.indexOf(p.id) > -1);
        console.log('Fetched Checkout Session.', stripeCheckoutSession);

        // google analytics
        emitPageView('/checkout/individuals/complete', 'New Purchase - Complete');
        let userEmail = stripeCheckoutSession.session.metadata?.userEmail ?? '';
        let amountTotal = stripeCheckoutSession.session.amount_total/100;
        let lineItems = stripeCheckoutSession.lineItems.data.map(i => {
          return { item_id: i.description, item_name: i.description, price: i.amount_total/100, quantity: i.quantity };
        });
        emitIndividualPurchaseComplete(amountTotal, stripeCheckoutSessionId, userEmail, lineItems);


        // empty cart
        let cart = window.cart;
        cart.emptyCart();
        // set 'complete' state
        this.setState({
          stage: 'complete',
          user,
          products,
          purchasedProducts,
          stripeCheckoutSessionId,
          stripeCheckoutSession,
        });
      }

      // if not 'complete', go to 'select' mode
      else {
        // google analytics
        emitPageView('/checkout/individuals/select', 'New Purchase - Select');
      // google analytics
      // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item_list
      emitEvent('view_item_list', {
        currency: 'USD',
        items: products.map(item => {
          return {
            item_id: item.name,
            item_name: item.name,
            price: item.stripeProduct.price.amount/100,
            quantity: 1,
          };
        }),
      });        
        this.setState({
          stage: 'select',
          user,
          products,
        });
      }

    }
    catch(e) {
      console.error('[PurchaseNew] Error in componentDidMount().', e);
    }
  }

  render() {

    if (this.state.stage === 'loading') {
      return (
        <div className='pi3purchaseContainer'>
          <div className='pi3PurchaseContent'>
            <LoadingCenter />
          </div>
        </div>
      );
    }

    if (this.state.stage === 'select') {

      // if user is logged in, and redirect to /courses
      if (this.state.user) {
        window.location.replace('/courses');
      }

      return (
        <div className='pi3purchaseContainer'>
          <div className='pi3PurchaseContent'>
            <CourseSelectPage
              products={this.state.products}
              cart={this.state.cart}
              navigate={this.navigate}
            />
          </div>
        </div>
      );
    }

    if (this.state.stage === 'signup') {
      return (
        <div className='pi3purchaseContainer'>
          <div className='pi3PurchaseContent'>
            <SignupForm
              form={this.state.userForm}
              validate={this.validateUserForm}
              onChange={this.mergeUserForm}
              navigate={this.navigate}
              submit={this.submit}
            />
          </div>
        </div>
      );
    }

    if (this.state.stage === 'checkout') {
      return (
        <div>
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret: this.state.stripeCheckoutClientSecret }}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </div>
      );
    }

    if (this.state.stage === 'complete') {

      return (
        <NewPurchaseSuccessMessage purchasedProducts={this.state.purchasedProducts} />
      );
    }

    return (
      <div>
        PurchaseNew
      </div>
    );

  }

}

export default PurchaseNew;



class CourseSelectPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onCartChange = this.onCartChange.bind(this);
  }
  componentDidMount() {
    let { items, total } = this.props.cart.getCart();
    this.setState({ items, total });
    this.props.cart.subscribe(this.onCartChange);
  }
  onCartChange() {
    this.setState({});
  }
  componentWillUnmount() {
    this.props.cart.unsubscribe(this.onCartChange);
  }
  render() {

    let cart = this.props.cart;

    {/* purchase2024 */}
    let specialOffers = '';
    // let specialOffers = (
    //   <div className='newPurchaseSpecialOffers'>
    //     <img src='https://pi2-web.s3.amazonaws.com/img/purchase/checkout_santa.png' alt='Special Offer: Save $200 on Foundations or Strategy' />
    //     <h3>Special Offer: Save $200 on Foundations or Strategy</h3>
    //     <p>When you purchase Product Management Foundations or Product Strategy before December 31, 2024, save $200 with <strong>code “<span>HOLIDAY</span>”</strong>.</p>
    //     <p><small>*Minimum purchase of $1199. Only available with Product Management Foundations or Product Strategy courses.</small></p>
    //   </div>
    // );

    {/* purchase2024 */}
    // let specialOffers2 = ''
    let specialOffers2 = (
      <div className='newPurchaseSpecialOffers'>
        <img src='https://pi2-web.s3.amazonaws.com/img/purchase/special-offer-cat@2x.png' alt='Want all the courses for $2499?' />
        <h3>Want all the courses for $2499?</h3>
        <p>Add all the courses to your cart and use <strong>code “<span>LEARN2025</span>”</strong> to receive them for $2499.</p>
        <p><small>*Other discounts do not apply</small></p>
      </div>
    );

    // let specialOffersDisclaimer = '';
    let specialOffersDisclaimer = (
      <p style={{ margin: '20px 0 0 0' }}>
        <small>
          "LEARN2025" coupon code is valid only for course purchases made between January 23, 2025 and July 31, 2025. Coupon is valid for first-time course enrollment only. Limit one coupon use per customer/account. Cannot be combined with other promotional codes, discounts, or special offers. Not valid on prior purchases. Non-transferable and not redeemable for cash. Must be applied at checkout to receive discount. Course access and materials are subject to our standard <a href="/p/click-through">Terms of Service</a>. Any attempt to share, redistribute, or sell this coupon code is prohibited. We reserve the right to cancel any enrollment or disable course access if the coupon is used fraudulently or in violation of these terms. Not valid for team enrollments or licenses, enterprise licenses or enrollments, or bulk purchases. All course fees remain the responsibility of the student. Course access is for one year from purchase date for live courses, and the future enrollment date for courses bought during a pre-sale.
        </small>
      </p>
    );


    let mainCourseSection = [];
    if (this.props.products && this.props.products.length > 0) {
      let mainCourses = this.props.products.filter(p => p.checkoutType === enums.IndividualProduct.Type.MainCourse);
      for (let mainCourse of mainCourses) {
        mainCourseSection.push(
          <div key={mainCourse.id}>
            <CourseSelectMainCourse course={mainCourse} cart={cart} />
          </div>
        );
      }
    }

    let deepDiveCourseItems = [];
    if (this.props.products && this.props.products.length > 0) {
      let dcs = this.props.products.filter(p => p.checkoutType === enums.IndividualProduct.Type.Dessert);
      for (let dc of dcs) {
        deepDiveCourseItems.push(<CourseSelectDeepDiveCourse key={dc.id} course={dc} cart={cart} />);
      }
    }
    let deepDiveCoursesSection = (
      <div className='deepDiveCoursesSelect'>
        {/* TODO pricing needs to be taken from Stripe */}
        <h2>Deep Dive Courses - <strong>$349</strong>/course </h2>
        <div className='newPurchaseSelectDessertCourses'>
          {deepDiveCourseItems}
        </div>
        
      </div>
    );


    return (
      <div className="courseSelectPage">

        <h1>Choose Your Courses</h1>
        {/* <br /> */}
        <p className='newPurchaseLargeParagraph'>
          Browse our course catalog below and add to cart. You can’t go wrong. All courses are accessible for one year from the purchase date, so you can learn on your own timeline.
        </p>

        {specialOffers}

        {mainCourseSection}

        {specialOffers2}

        {deepDiveCoursesSection}

        <CourseSelectTotal cart={cart} navigate={this.props.navigate} /> 

        {specialOffersDisclaimer}
      </div>
    );
  }
}


class CourseSelectMainCourse extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let course = this.props.course;
    let cart = this.props.cart;

    let button = '';
    let isCourseAdded = cart.isItemInCart(course);
    if (isCourseAdded) {
      button = (
        <button className='pi4button pi4activeButton pi2uppercase' onClick={() => cart.remove(course)}>
          <img src='https://pi2-web.s3.amazonaws.com/img/purchase/added@2x.png' />
          Added to cart
        </button>
      );
    }
    else {
      button = (
        <button className='pi4button pi2uppercase' onClick={() => cart.add(course)}
        >Add to cart</button>
      );
    }

    let ribbon = '';
    if (course.isComingSoon) {
      ribbon = (<div className='newPurchaseSelectBadge'>COMING SOON</div>);
    }
    else if (course.isNew) {
      ribbon = (<div className='newPurchaseSelectBadge'>NEW COURSE</div>);
    }

    return (
      <div className='newPurchaseSelectMainCourse'>
        {ribbon}
        <div className='newPurchaseSelectMainCourseContainer'>
          <div className='mainCourseIcon'>
            <img src={course.checkoutThumbnailLink} alt={course.name} />
          </div>
          <div className='mainCourseContent'>
            <h3>{course.name}</h3>
            <p>{course.description}</p>
            <p><a href={this.props.course.checkoutOverviewLink} target='_new'>View Curriculum</a></p>
          </div>
          <div className='mainCoursePrice'>
            <div className='mainCoursePriceAmount'>{toPrettyDollarsRound(course.stripeProduct.price.amount)}</div>
            {button}
          </div>
        </div>
      </div>
    );
  }
}


class CourseSelectDeepDiveCourse extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    let course = this.props.course;
    let cart = this.props.cart;

    let button = '';
    let isCourseAdded = cart.isItemInCart(course);
    if (isCourseAdded) {
      button = (
        <button className='pi4button pi4activeButton pi2uppercase' onClick={() => cart.remove(course)}
        >
          <img src='https://pi2-web.s3.amazonaws.com/img/purchase/added@2x.png' />
          Added to cart
        </button>
      );
    }
    else {
      button = (
        <button className='pi4button pi2uppercase' onClick={() => cart.add(course)}
        >Add to cart</button>
      );
    }

    let ribbon = '';
    if (course.isComingSoon) {
      ribbon = (<div className='newPurchaseSelectBadge'>COMING SOON</div>);
    }
    else if (course.isNew) {
      ribbon = (<div className='newPurchaseSelectBadge'>NEW COURSE</div>);
    }

    return (
      <div className='newPurchaseSelectDessertCourse'>
        {ribbon}
        <div className='newPurchaseSelectDessertCourseContainer'>
          <h3>{this.props.course.name}</h3>
          <img src={course.checkoutThumbnailLink} alt={course.name} />
          <p>{this.props.course.subtitle}</p>
          <p><a href={this.props.course.checkoutOverviewLink} target='_new'>View Curriculum</a></p>
          {/* {toPrettyDollarsRound(this.props.course.price.amount)} */}
          <br />
          {button}
        </div>
      </div>
    );
  }
}


class CourseSelectTotal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {

    let cartData = this.props.cart.getCart();

    // nothing in cart yet
    if (cartData.items && cartData.items.length === 0) {
      console.log('[CourseSelectTotal] No items in cart.');
      return '';
    }

    // count selected items
    let title = '';
    if (cartData.items && cartData.items.length > 0) {
      if (cartData.items.length === 1) {
        title = `Total (1 Selected Item)`;
      }
      else {
        title = `Total (${cartData.items.length} Selected Items)`;
      }
    }

    return (

      <div className='courseSelectTotalContainer'>
        <div className='courseSelectTotal'>
          <div className='courseSelectTotalLeft'>
            <h2>{title}</h2>
            <div>
              <span className='couseSelectTotalAmount'>
                {toPrettyDollarsRound(cartData.total)}
              </span>
              <p>
                If you have a discount coupon, you'll be able to enter it on the upcoming Checkout page.
              </p>
            </div>
          </div>
          <div className='courseSelectTotalRight'>
            <button className='pi4button pi4buttonLarge pi4activeButton' onClick={() => this.props.navigate('signup')}>Continue to Sign Up</button>
          </div>
        </div>
      </div>

    );
  }
}


class SignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validate: false, // TODO deprecated?
      isUserAlreadyThere: false,
    };
    this.submit = this.submit.bind(this);
  }
  async submit() {
    try {
      // attempt validation
      let tree = this.props.validate(null, this.state.form);
      let isHealthy = validation.isTreeHealthy(tree);
      this.props.onChange({ validate: true });
      if (isHealthy) {
        // console.log('[SignupForm] Tree is healthy!');

        // TODO check here if the email is already in use
        let isUserAlreadyThere = await validateEmail(this.props.form.email);

        this.setState({ isUserAlreadyThere: isUserAlreadyThere.result });

        // if user already exists, prevent submission
        // user doesn't already exist, so continue
        if (isUserAlreadyThere.result === true) {
          return;
        }

        // INPROG either navigate to invoice form, or submit to server to start stripe
        if (this.props.form.invoice) {
          // console.log('[SignupForm] Invoice should be generated, heading to invoice view');
          this.props.navigate('invoice');
        }
        else {
          // console.log('[SignupForm] Invoice should not be generated, heading straight to submission');
          this.props.submit();
        }

      }
      else {
        console.log('[SignupForm] Tree is not healthy.');
      }
    } catch(e) {
      console.error(`[SignupForm] Error handling submit().`, e);
    }
  }


  render() {

    let form = this.props.form;
    let validate = this.props.validate;
    let onChange = this.props.onChange;

    let termsLabel = <span>I agree to <a href='/p/click-through' target='_blank'>Product Institute's Terms</a></span>;

    // https://www.figma.com/file/43dgJMptWcAI2HgMh9e5sq/Product-Institute---Purchase-Flow-%2F-V2
    let emailValidationMessage = '';
    if (this.state.isUserAlreadyThere) {
      emailValidationMessage = (
        <p style={{ color: '#f00', margin: '5px 0 20px   0' }}>
          We're sorry, but that email is already taken.
          Please try another email to continue, or login if the email belongs to you.
          If you continue to have problems, please <a style={{ color: '#f00' }} href='/p/contact-us' target='_new'>contact us</a>.
        </p>
      );
    }

    let emailClass = validate('email');
    if (this.state.isUserAlreadyThere) {
      emailClass = 'invalid';
    }

    return (
      <div className='newPurchaseSignupForm' id='newPurchaseSignupForm'>
        <h1>Sign Up</h1>
        {/* <br /> */}
        <p className='newPurchaseLargeParagraph'>Create a Product Institute account to complete checkout. An account is required to sign in and view your courses.</p>
        <div className='pi2form'>

          <div className="formRow">
            <label htmlFor="input-name">First Name</label>
            <input type='text' className={` ${validate('nameFirst')}`} id='input-name-first' name='nameFirst' value={form.nameFirst} onChange={e => onChange({ nameFirst: e.target.value })} placeholder='e.g. John' />
          </div>

          <div className="formRow">
            <label htmlFor="input-name">Last Name</label>
            <input type='text' className={` ${validate('nameLast')}`} id='input-name-last' name='nameLast' value={form.nameLast} onChange={e => onChange({ nameLast: e.target.value })} placeholder='e.g. Smith' />
          </div>

          <div className="formRow">
            <label htmlFor="input-email">Email Address</label>
            <input type='text' className={emailClass} id='input-email' name='email' value={form.email} onChange={e => onChange({ email: e.target.value })} placeholder='e.g. jsmith@company.com' />
            {emailValidationMessage}
          </div>

          <br />

          <div className="formRow">
            <label>
              <FormCheckbox isChecked={form.terms} label={termsLabel} onChange={value => onChange({ terms: value })} />
            </label>
          </div>

          <div className="formRow">
            <label>
              <FormCheckbox isChecked={form.marketing} label="I agree to receive instructional and promotional emails" onChange={marketing => onChange({ marketing })} />
            </label>
          </div>

          <div className="formRow newPurchaseSignupFormSubmit">
            {
              form.terms ?
                <button className='pi4button pi4buttonLarge' onClick={this.submit}>Checkout</button> : 
                <button className='pi4button pi4buttonLarge' disabled>Checkout</button>
            }

          </div>

        </div>
      </div>
    );
  }
}

function NewPurchaseSuccessMessage(props) {
  let purchasedProducts = props.purchasedProducts;
  return (
    <div className='repurchaseSuccessMessage'>
      <div className='repurchaseSuccessMessageInner'>
        <h1>Purchase Complete!</h1>
        <br />
        <Image src='/static/images/purchase-success-cat-walking.png' alt='Cat walking' />
        <br />
        <br />
        <p>
          We're processing your payment! Your account will be created in a few minutes and {toHumanArray(purchasedProducts.map(p => p.name))} will be added to your course library.
          <br />
          Please wait until you receive your invoice, and then you'll be able to login using just your email.
        </p>
        <br />
        <a href='/login'>
          <button className='pi3button'>Return to Login</button>
        </a>
      </div>
    </div>
  );
}